import React, { useRef, useEffect } from "react";

const PhoneInput = ({ value, onChange }) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, 11);
  }, []);

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && event.target.value === "" && index > 1) {
      event.preventDefault();
      const prevInput = inputRefs.current[index - 1];
      if (prevInput) {
        prevInput.focus();
        prevInput.value = "";
        handleInputChange(index - 1, "");
      }
    }
  };

  const handleInput = (index, inputValue) => {
    const cleanedValue = inputValue.replace(/[^0-9]/g, "");
    handleInputChange(index, cleanedValue);

    if (cleanedValue.length === 1 && index < inputRefs.current.length - 1) {
      const nextInput = inputRefs.current[index + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleInputChange = (index, inputValue) => {
    const newValue = value.split("");
    newValue[index] = inputValue;
    onChange(newValue.join(""));
  };

  return (
    <div className="flex w-full mb-2">
      <input
        type="tel"
        value="8"
        className="input-box border-2 border-gray-300 text-center"
        readOnly
        ref={(el) => (inputRefs.current[0] = el)}
      />
      {[...Array(10)].map((_, index) => (
        <input
          key={index + 1}
          ref={(el) => (inputRefs.current[index + 1] = el)}
          type="tel"
          maxLength="1"
          value={value[index + 1] || ""}
          onChange={(e) => handleInput(index + 1, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index + 1, e)}
          className="input-box border-2 border-gray-300 text-center"
          required
        />
      ))}
    </div>
  );
};

export default PhoneInput;
