import React, { useState } from "react";
import HomePage from "./pages/HomePage";
import ConfirmationPage from "./pages/ConfirmationPage";

function App() {
  const [orderSubmitted, setOrderSubmitted] = useState(false);
  const [submittedData, setSubmittedData] = useState(null);

  const handleOrderSubmit = (formData) => {
    console.log(formData);
    setSubmittedData(formData);
    setOrderSubmitted(true);
  };

  const handleNewOrder = () => {
    setOrderSubmitted(false);
    setSubmittedData(null);
  };

  return (
    <div className="App">
      {orderSubmitted ? (
        <ConfirmationPage onNewOrder={handleNewOrder} data={submittedData} />
      ) : (
        <HomePage onOrderSubmit={handleOrderSubmit} />
      )}
    </div>
  );
}

export default App;
