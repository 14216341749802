import { CONFIG } from "./config";

export const submitForm = async (submissionData) => {
  try {
    const response = await fetch(CONFIG.API_URL, {
      method: "POST",
      mode: "no-cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(submissionData),
    });
    return response;
  } catch (error) {
    console.error("Error submitting form:", error);
    throw error;
  }
};
