import React from "react";
import OrderForm from "../components/OrderForm";


const HomePage = ({ onOrderSubmit }) => {
  return (
    <div className="bg-gray-200 ">
     
      <h1 className="text-center text-[18px] mb-1">
        Форма приема заявок на вывоз и/или поставку масла
      </h1>
      <OrderForm onSubmit={onOrderSubmit} />
    </div>
  );
};

export default HomePage;
